<template>
  <div class="">
    <div v-if="isOpenDiv">
      <!-- <v-row>
        <h1 class="mx-auto my-8" style="color: #235081; font-size: 30px;">
          ลอตเตอรี่ของฉัน
        </h1>
      </v-row> -->
      <v-app-bar
        app
        absolute
        color="#235081"
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
        class="mt-n16 ma-3 pa-1"
      >
        <v-spacer></v-spacer>
        <h1 style="font-size: 30px" class="mt-5 white--text">
          ประวัติสั่งซื้อ
        </h1>
        <v-spacer></v-spacer>

        <template v-slot:extension>
          <v-tabs color="black">
            <!-- <v-tabs-slider color=""></v-tabs-slider> -->
            <v-tab
              v-for="item in tabitems"
              :key="item.key"
              @click="tab(item)"
              style="color: white"
            >
              <span style="font-size: 14px; font-weight: bold">{{
                item.text
              }}</span>
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>

      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="650"
      >
        <v-container style="height: 100px"> </v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" style="text-align: -webkit-center">
            <v-btn
              large
              style="
                background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
              "
              @click="gotomember()"
              >แก้ไขข้อมูลส่วนตัว</v-btn
            >
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" class="text-center">
            <span style="color: red"
              >** หากไม่ได้รับ SMS สั่งซื้อสำเร็จใน 15-30 นาที
              ออเดอร์อาจจะส่งคำสั่งซื้อไม่สำเร็จ กรุณาติดตอเจ้าหน้าแอดมินทางไลน์
              <br />
              หรือ โทร. 02-114-1326 **
            </span>
          </v-col>
        </v-row>
        <v-row v-if="tabs !== 'lao'">
          <v-col v-if="tabs !== 'lao'" class="">
            <!-- <v-row v-if="tabs == 'powerball'">
          <v-col v-if="tabs == 'powerball'" class=""> -->
            <v-card
              style="font-size: 18px; border: 1px solid #235081"
              class="py-4 px-4 mb-4"
              v-for="(lotto, index) in orderpowerball"
              :key="index"
              :search="search"
            >
              <div
                class="my-4 py-2 text-center"
                style="padding: 5px; border: 3px solid #235081"
              >
                <span style="color: dark">สถานะ: </span>

                <span v-if="lotto.status == 'รอคอนเฟิร์ม'">
                  <span
                    style="
                      padding: 3px;
                      border: 3px solid #f5222d;
                      color: #f5222d;
                    "
                    >รอการยืนยัน</span
                  >
                </span>
                <span v-if="lotto.status == 'รอการชำระเงิน'">
                  <span
                    style="
                      padding: 3px;
                      border: 3px solid #f5222d;
                      color: #f5222d;
                    "
                    >รอการชำระเงิน</span
                  >
                </span>
                <span v-if="lotto.status == 'ชำระเงินสำเสร็จ'">
                  <span
                    style="
                      padding: 3px;
                      border: 3px solid #faad14;
                      color: #faad14;
                    "
                    >ชำระเงินสำเสร็จ</span
                  >
                </span>
                <span v-if="lotto.status == 'สั่งซื้อสำเร็จ'">
                  <span
                    style="
                      padding: 3px;
                      border: 3px solid #52c41a;
                      color: #52c41a;
                    "
                  >
                    สั่งซื้อสำเร็จ
                  </span>
                </span>

                <span
                  v-if="lotto.status == 'มีปัญหา'"
                  style="
                    padding: 3px;
                    border: 3px solid #f5222d;
                    color: #f5222d;
                  "
                >
                  <span>มีปัญหา</span>
                </span>
                <span
                  v-if="lotto.status == 'ยกเลิก'"
                  style="
                    padding: 3px;
                    border: 3px solid #f5222d;
                    color: #f5222d;
                  "
                >
                  <span>ยกเลิก</span>
                </span>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  หมายเลขคำสั่งซื้อ
                </p>
                <p>{{ lotto.transactionNo }}</p>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  งวดวันที่
                </p>
                <p>{{ convertDate(lotto.createdAt) }}</p>
              </div>
              <!-- <span>{{ lotto.transactionNo }}</span>
            <span>{{ lotto.totalPrice }}</span> -->
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  วัน - เวลา ที่สั่งซื้อ
                </p>
                <p>{{ convertDateBuy(lotto.createdAt) }}</p>
              </div>
              <div class="mb-4 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  ราคารวม
                </p>
                <p>{{ lotto.totalPrice }} บาท</p>
              </div>
              <div>
                <v-btn
                  large
                  block
                  style="
                    background: linear-gradient(#3e93d7 0%, #003e81 100%);
                    color: white;
                  "
                  @click="tranxDetailPowerball(lotto.id, tabs)"
                >
                  รายละเอียด</v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="tabs == 'lotterythai'">
          <v-col v-if="tabs == 'lotterythai'" class="">
            <v-card
              style="font-size: 18px; border: 1px solid #235081"
              class="py-4 px-4 mb-4"
              v-for="(lotto, index) in orderThai"
              :key="index"
              :search="search"
            >
              <div
                class="my-4 py-2 text-center"
                style="padding: 5px; border: 3px solid #235081"
              >
                สถานะ

                <span v-if="lotto.status == 'PENDING'">
                  <span
                    style="
                      padding: 3px;
                      border: 3px solid #f5222d;
                      color: #f5222d;
                    "
                    >รอการชำระเงิน</span
                  >
                </span>
                <span v-if="lotto.status == 'ชำระเงินสำเร็จ'">
                  <span
                    style="
                      padding: 3px;
                      border: 3px solid #faad14;
                      color: #faad14;
                    "
                    >ชำระเงินสำเร็จ</span
                  >
                </span>
                <span v-if="lotto.status == 'SUCCESS'">
                  <span
                    v-if="lotto.slipImg == ''"
                    style="background-color: #235081"
                    >รออัพโหลดสลิป</span
                  >
                  <span v-else style="color: green">ยืนยันสลิปถูกต้อง</span>
                </span>
                <span v-if="lotto.status == 'REJECT'">
                  <span
                    v-if="lotto.slipImg == ''"
                    style="background-color: #235081"
                    >รออัพโหลดสลิป</span
                  >
                  <span v-else>มีปัญหา</span>
                </span>
                <span
                  v-if="lotto.status == 'CANCEL'"
                  style="background-color: red"
                >
                  <span>ยกเลิก</span>
                </span>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  หมายเลขคำสั่งซื้อ
                </p>
                <p>{{ lotto.transactionNo }}</p>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  ประจำงวดที่
                </p>
                <p>{{ datefrom }}</p>
              </div>
              <!-- <span>{{ lotto.transactionNo }}</span>
            <span>{{ lotto.totalPrice }}</span> -->
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  วัน - เวลา ที่สั่งซื้อ
                </p>
                <p>{{ convertDate(lotto.createdAt) }}</p>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  จำนวนใบที่สั่งซื้อ
                </p>
                <p>{{ lotto.lotteryTransaction.length }} ใบ</p>
              </div>
              <div class="mb-4 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  ราคารวม
                </p>
                <p>{{ lotto.totalPrice }} บาท</p>
              </div>
              <div>
                <v-btn
                  large
                  block
                  style="background: linear-gradient(#3e93d7 0%, #003e81 100%)"
                  @click="tranxDetail(lotto.id)"
                >
                  รายละเอียด</v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6" sm="6" v-if="tabs == 'order'">
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="search"
                dense
                hide-details
                outlined
                placeholder="ค้นหาหมายเลขลอตเตอรี่"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-select
                class=""
                v-model="search"
                dense
                hide-details
                outlined
                placeholder="เลือกงวด"
              ></v-select>
            </v-col>
          </v-col>
          <v-col v-if="tabs == 'order'" class="">
            <v-card
              style="font-size: 18px; border: 1px solid #235081"
              class="py-4 px-4 mb-4"
              v-for="(lotto, index) in orderlotto"
              :key="index"
              :search="search"
            >
              <div
                class="my-4 py-2 text-center"
                style="background-color: #235081"
              >
                สถานะ
                <span
                  v-if="lotto.status == 'PENDING'"
                  style="background-color: #235081"
                >
                  <span v-if="lotto.slipImg == ''">รออัพโหลดสลิป</span>
                  <span v-else>รอตรวจสอบ</span>
                </span>
                <span v-if="lotto.status == 'SUCCESS'">
                  <span
                    v-if="lotto.slipImg == ''"
                    style="background-color: #235081"
                    >รออัพโหลดสลิป</span
                  >
                  <span v-else style="color: green">ยืนยันสลิปถูกต้อง</span>
                </span>
                <span v-if="lotto.status == 'REJECT'">
                  <span
                    v-if="lotto.slipImg == ''"
                    style="background-color: #235081"
                    >รออัพโหลดสลิป</span
                  >
                  <span v-else>มีปัญหา</span>
                </span>
                <span
                  v-if="lotto.status == 'CANCEL'"
                  style="background-color: red"
                >
                  <span>ยกเลิก</span>
                </span>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  หมายเลขคำสั่งซื้อ
                </p>
                <p>{{ lotto.transactionNo }}</p>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  ประจำงวดที่
                </p>
                <p>{{ datefrom }}</p>
              </div>
              <!-- <span>{{ lotto.transactionNo }}</span>
            <span>{{ lotto.totalPrice }}</span> -->
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  วัน - เวลา ที่สั่งซื้อ
                </p>
                <p>{{ convertDate(lotto) }}</p>
              </div>
              <div class="mb-2 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  จำนวนใบที่สั่งซื้อ
                </p>
                <p>{{ lotto.lotteryTransaction.length }} ใบ</p>
              </div>
              <div class="mb-4 ml-4">
                <p style="color: #004085; font-weight: 800; font-size: 20px">
                  ราคารวม
                </p>
                <p>{{ lotto.totalPrice }} บาท</p>
              </div>
              <div>
                <v-btn
                  large
                  block
                  style="
                    background: linear-gradient(
                      180deg,
                      #fbe599 0%,
                      #235081 100%
                    );
                  "
                  @click="
                    tranxDetailLao(lotto.lotteryTransaction[0].transactionId)
                  "
                >
                  รายละเอียด</v-btn
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-col cols="12" v-if="tabs == 'lottery'" class="pt-10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="4" sm="4">
              <v-menu
                v-model="menufrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datefrom"
                    label="เลือกงวดที่ออกรางวัล"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    hide-details
                    class="mt-6"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datefrom"
                  @input="menufrom = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" sm="2" class="text-center">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #235081 100%);
                "
                @click="getLottery()"
              >
                ค้นหา</v-btn
              >
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="12" class="mb-10">
              <v-card
                v-for="(i, index) in lotto"
                :key="index"
                style="
                  background-color: #97b189;
                  color: white;
                  font-weight: 800;
                "
                class="pa-2 my-4"
                elevation="2"
              >
                <v-container>
                  <v-row align="center" justify="center"
                    ><v-col cols="12" md="6" sm="6">
                      <v-col cols="12">
                        <v-img :src="i.lottery.urlImage" lazy="loaded" />
                      </v-col>
                      <div class="info2">
                        <v-img
                          max-width="80px"
                          class="mx-auto mb-2"
                          src="@/assets/eriam-logo.png"
                        ></v-img>
                      </div>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <span>งวดประจำวันที่ {{ i.lottery.lotteryDate }}</span>
                      <br />
                      <span>หมายเลขลอตเตอรี่ : {{ i.lottery.lotteryNo }}</span>
                      <br />
                      <span>จำนวน : {{ i.lottery.fixedStock }} ใบ</span>
                      <br />
                      <span>วันที่ซื้อ : {{ convertDate(i) }}</span>
                      <br />
                      <v-col cols="12" v-if="i.deliveryAddress != null">
                        สถานที่จัดส่ง
                        <span>ที่อยู่ : {{ i.deliveryAddress.address }}</span>
                        <span>
                          ตำบล/เขต : {{ i.deliveryAddress.subDistrict }}
                        </span>
                        <span>
                          อำเภอ/แขวง : {{ i.deliveryAddress.district }}</span
                        >
                        <span>
                          จังหวัด : {{ i.deliveryAddress.province }}
                        </span>
                        <span>
                          รหัสไปรษณีย์ : {{ i.deliveryAddress.postcode }}
                        </span>
                      </v-col>
                      <v-btn
                        @click="tranxDetail(i.transactionId)"
                        class="text-center mt-2"
                        text
                        style="
                          background: linear-gradient(
                            180deg,
                            #fbe599 0%,
                            #235081 100%
                          );
                        "
                        >ดูการสั่งซื้อ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" v-if="tabs == 'winprize'" class="pt-10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="12">
              <v-card class="pa-2 my-4 winprizecard" elevation="2">
                <v-container>
                  <v-row align="center" justify="center"
                    ><v-col cols="12" md="6" sm="6">
                      <v-col cols="12">
                        <v-img src="@/assets/examplelot.png" lazy="loaded" />
                      </v-col>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <span>งวดประจำวันที่ 16 พฤษจิกายน 2562</span>
                      <br />
                      <span>หมายเลขลอตเตอรี่ : 990623</span>
                      <br />
                      <span>จำนวน : 1 ใบ</span>
                      <br />
                      <span>วันที่ซื้อ : 6 พฤษจิกายน 2562</span>
                      <br />
                      <!-- <v-col cols="12" v-if="i.deliveryAddress != null">
                        สถานที่จัดส่ง
                        <span>ที่อยู่ : {{ i.deliveryAddress.address }}</span>
                        <span>
                          ตำบล/เขต : {{ i.deliveryAddress.subDistrict }}
                        </span>
                        <span>
                          อำเภอ/แขวง : {{ i.deliveryAddress.district }}</span
                        >
                        <span>
                          จังหวัด : {{ i.deliveryAddress.province }}
                        </span>
                        <span>
                          รหัสไปรษณีย์ : {{ i.deliveryAddress.postcode }}
                        </span>
                      </v-col> -->
                      <v-col cols="12">
                        <v-chip color="green" dark>
                          คุณถูกรางวัลเลขท้าย 2 ตัว (ตัวอย่าง)</v-chip
                        >
                      </v-col>
                      <v-btn
                        class="text-center"
                        text
                        dark
                        style="text-decoration: underline"
                        @click="winprize()"
                        >ขึ้นรางวัลที่นี่</v-btn
                      >
                      <v-btn
                        @click="tranxDetail(i.transactionId)"
                        class="text-center"
                        text
                        style="font-weight: 800"
                        >ดูการสั่งซื้อ</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="12" v-if="tabs == 'winprize'" class="pt-10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="6" sm="12">
              
            </v-col>
          </v-row>
        </v-col> -->
      </v-sheet>

      <!-- <v-row>
        <v-tabs background-color="" centered>
          <v-tabs-slider color=""></v-tabs-slider>
          <v-tab
            v-for="item in tabitems"
            :key="item.key"
            @click="tab(item)"
            style="font-size: 18px"
          >
            {{ item.text }}
          </v-tab>
        </v-tabs>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" sm="6" v-if="tabs == 'order'"
          ><v-col cols="12" md="6" sm="6" class="right">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหาหมายเลขลอตเตอรี่"
            ></v-text-field> </v-col
        ></v-col>
        <v-col cols="12" v-if="tabs == 'order'" class="mb-10 mx-2">
          <v-data-table
            :headers="headers"
            :items="orderlotto"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            :mobile-breakpoint="0"
          >
            <template v-slot:[`item.count`]="{ index }">
              <span>{{ item.count.indexOf() + 1 }}</span>
              <span>{{ index + 1 }}</span>
            </template>

            <template v-slot:[`item.updatedAt`]="{ item }">
              {{ convertDate(item) }}
            </template>

            <template v-slot:[`item.lotteryNo`]="{ item }">
              <span
                v-for="(i, index) in item.lotteryTransaction"
                :key="index"
                >{{ i.lottery.lotteryNo + " " }}</span
              >
            </template>

            <template v-slot:[`item.isDelivery`]="{ item }">
              <span v-if="item.isDelivery == false">ไม่จัดส่ง</span>
              <span v-if="item.isDelivery == true">จัดส่ง</span>
            </template>

            <template v-slot:[`item.deliveryStatus`]="{ item }">
              <span v-if="item.deliveryStatus == 'WAITING'">รอการจัดส่ง</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'PENDING'" color="orange" dark>
                {{ item.status }}</v-chip
              >
              <v-chip v-if="item.status == 'SUCCESS'" color="green" dark>
                {{ item.status }}</v-chip
              >
              <v-chip v-if="item.status == 'BOOKING'" color="blue" dark>{{
                item.status
              }}</v-chip>
              <v-chip v-if="item.status == 'REJECT'" color="red" dark>{{
                item.status
              }}</v-chip>
              <v-chip v-if="item.status == 'CANCEL'" color="brown" dark>{{
                item.status
              }}</v-chip>
              <v-chip v-if="item.status == 'ถูกจัดส่งแล้ว'" color="green" dark
                ><v-icon>mdi-check-circle</v-icon>{{ item.status }}</v-chip
              >
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip v-if="item.status == 'PENDING'" color="orange" dark>
                <span v-if="item.slipImg == ''">รออัพโหลดสลิป</span>
                <span v-else>รอตรวจสอบ</span>
              </v-chip>
              <v-chip v-if="item.status == 'SUCCESS'" color="green" dark>
                <span v-if="item.slipImg == ''">รออัพโหลดสลิป</span>
                <span v-else>ยืนยันสลิปถูกต้อง</span>
              </v-chip>
              <v-chip v-if="item.status == 'BOOKING'" color="blue" dark>{{
                item.status
              }}</v-chip>
              <v-chip v-if="item.status == 'REJECT'" color="red" dark>
                <span v-if="item.slipImg == ''">รออัพโหลดสลิป</span>
                <span v-else>มีปัญหา</span>
              </v-chip>
              <v-chip v-if="item.status == 'CANCEL'" color="brown" dark>
                <span>ยกเลิก</span>
              </v-chip>
              <v-chip v-if="item.status == 'ถูกจัดส่งแล้ว'" color="green" dark
                ><v-icon>mdi-check-circle</v-icon>{{ item.status }}</v-chip
              >
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <v-icon
                  @click="tranxDetail(item.lotteryTransaction[0].transactionId)"
                  >mdi-magnify</v-icon
                >
              </v-row>
            </template>
          </v-data-table>
        </v-col>
        <v-col v-if="tabs == 'order'" class="">
          <v-card
            style="border: 1px solid #00739c; font-size: 18px;"
            class="py-4 mb-4"
            v-for="(lotto, index) in orderlotto"
            :key="index"
            :search="search"
          >
            <div class="mb-2 ml-4">
              หมายเลขคำสั่งซื้อ {{ lotto.transactionNo }}
            </div>
            <div class="mb-2 ml-4">ราคารวม {{ lotto.totalPrice }} บาท</div>
            <span>{{ lotto.transactionNo }}</span>
            <span>{{ lotto.totalPrice }}</span>
            <div class="mb-2 ml-4">วันที่จ่ายเงิน {{ convertDate(lotto) }}</div>
            <div class="text-center mb-2">
              สถานะ
              <span v-if="lotto.status == 'PENDING'" style="color: orange">
                <span v-if="lotto.slipImg == ''">รออัพโหลดสลิป</span>
                <span v-else>รอตรวจสอบ</span>
              </span>
              <span v-if="lotto.status == 'SUCCESS'">
                <span v-if="lotto.slipImg == ''" style="color: orange"
                  >รออัพโหลดสลิป</span
                >
                <span v-else style="color: green">ยืนยันสลิปถูกต้อง</span>
              </span>
              <span v-if="lotto.status == 'REJECT'">
                <span v-if="lotto.slipImg == ''" style="color: orange"
                  >รออัพโหลดสลิป</span
                >
                <span v-else style="color: red">มีปัญหา</span>
              </span>
              <span v-if="lotto.status == 'CANCEL'" style="color: brown">
                <span>ยกเลิก</span>
              </span>
            </div>
            <div>
              <v-btn
                large
                block
                style="background: linear-gradient(180deg, #fbe599 0%, #235081 100%);"
                @click="tranxDetail(lotto.transactionId)"
              >
                รายละเอียด</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-col cols="12" v-if="tabs == 'lottery'" class="pt-10">
        <v-row align="center" justify="center">
          <v-col cols="12" md="4" sm="4">
            <v-menu
              v-model="menufrom"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datefrom"
                  label="เลือกงวดที่ออกรางวัล"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  hide-details
                  class="mt-6"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="datefrom"
                @input="menufrom = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2" sm="2" class="text-center">
            <v-btn
              style="background: linear-gradient(180deg, #fbe599 0%, #235081 100%);"
              @click="getLottery()"
            >
              ค้นหา</v-btn
            >
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" sm="12">
            <v-card
              v-for="(i, index) in lotto"
              :key="index"
              style="background-color: #00739c; color: white; font-weight: 800"
              class="pa-2 my-4"
              elevation="2"
            >
              <v-container>
                <v-row align="center" justify="center"
                  ><v-col cols="12" md="6" sm="6">
                    <v-col cols="12">
                      <v-img :src="i.lottery.urlImage" lazy="loaded" />
                    </v-col>
                    <div class="info2">
                      <v-img
                        max-width="80px"
                        class="mx-auto mb-2"
                        src="@/assets/eriam-logo.png"
                      ></v-img>
                    </div>
                  </v-col> -->
      <!-- createdAt: "2021-04-24T18:16:52.000Z"
                        id: 137
                        lotteryDate: "2020-08-16"
                        lotteryNo: 246018
                        owner: "admin"
                        price: 80
                        set: 3
                        stock: 0
                        time: 23
                        updatedAt: "2021-06-28T09:32:29.000Z"
                                  urlImage: "https://readylo -->
      <!-- <v-col cols="12" md="6" sm="6">
                    <span>งวดประจำวันที่ {{ i.lottery.lotteryDate }}</span>
                    <br />
                    <span>หมายเลขลอตเตอรี่ : {{ i.lottery.lotteryNo }}</span>
                    <br />
                    <span>จำนวน : {{ i.lottery.fixedStock }} ใบ</span>
                    <br />
                    <span>วันที่ซื้อ : {{ convertDate(i) }}</span>
                    <br />
                    <v-col cols="12" v-if="i.deliveryAddress != null">
                      สถานที่จัดส่ง
                      <span>ที่อยู่ : {{ i.deliveryAddress.address }}</span>
                      <span>
                        ตำบล/เขต : {{ i.deliveryAddress.subDistrict }}
                      </span>
                      <span>
                        อำเภอ/แขวง : {{ i.deliveryAddress.district }}</span
                      >
                      <span> จังหวัด : {{ i.deliveryAddress.province }} </span>
                      <span>
                        รหัสไปรษณีย์ : {{ i.deliveryAddress.postcode }}
                      </span>
                    </v-col>
                    <v-btn
                      @click="tranxDetail(i.transactionId)"
                      class="text-center mt-2"
                      text
                      style="background: linear-gradient(180deg, #fbe599 0%, #235081 100%);"
                      >ดูการสั่งซื้อ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="tabs == 'winprize'" class="pt-10">
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" sm="12">
            <v-card
              v-for="(i, index) in lotto"
              :key="index"
              style="
                background: linear-gradient(180deg, #fbe599 0%, #235081 100%);
                color: white;
                font-weight: 800;
              "
              class="pa-2 my-4"
              elevation="2"
            >
              <v-container>
                <v-row align="center" justify="center"
                  ><v-col cols="12" md="6" sm="6">
                    <v-col cols="12">
                      <v-img :src="i.lottery.urlImage" lazy="loaded" />
                    </v-col>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <span>งวดประจำวันที่ {{ i.lottery.lotteryDate }}</span>
                    <br />
                    <span>หมายเลขลอตเตอรี่ : {{ i.lottery.lotteryNo }}</span>
                    <br />
                    <span>จำนวน : {{ i.lottery.fixedStock }} ใบ</span>
                    <br />
                    <span>วันที่ซื้อ : {{ convertDate(i) }}</span>
                    <br />
                    <v-col cols="12" v-if="i.deliveryAddress != null">
                      สถานที่จัดส่ง
                      <span>ที่อยู่ : {{ i.deliveryAddress.address }}</span>
                      <span>
                        ตำบล/เขต : {{ i.deliveryAddress.subDistrict }}
                      </span>
                      <span>
                        อำเภอ/แขวง : {{ i.deliveryAddress.district }}</span
                      >
                      <span> จังหวัด : {{ i.deliveryAddress.province }} </span>
                      <span>
                        รหัสไปรษณีย์ : {{ i.deliveryAddress.postcode }}
                      </span>
                    </v-col>
                    <v-col cols="12">
                      <v-chip color="red" dark>
                        คุณถูกรางวัลแลขท้าย 2 ตัว</v-chip
                      >
                    </v-col>
                    <v-btn
                      class="text-center"
                      text
                      dark
                      style="text-decoration: underline"
                      @click="winprize()"
                      >ขึ้นรางวัลที่นี่</v-btn
                    >
                    <v-btn
                      @click="tranxDetail(i.transactionId)"
                      class="text-center"
                      text
                      style="font-weight: 800"
                      >ดูการสั่งซื้อ</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col> -->
    </div>

    <div v-if="!isOpenDiv" class="mt-4">
      <v-card class="ma-4 pa-10 text-center mb-10" style="background: #9ab08a">
        <v-row align="center" justify="center">
          <h1 style="color: white; font-weight: 800">
            ขออภัยท่านยังไม่ได้เข้าสู่ระบบ
          </h1>
        </v-row>
        <v-row align="center" justify="center">
          <span style="color: white; font-weight: 800"
            >กรุณาเข้าสู่ระบบ หรือ สมัครสมาชิก</span
          >
        </v-row>
        <v-row class="mt-2" justify="center" align="center"
          ><v-col cols="12">
            <v-btn
              style="
                background: linear-gradient(180deg, #fbe599 0%, #235081 100%);
              "
              @click="member()"
              >เข้าสู่ระบบ / สมัครสมาชิก</v-btn
            ></v-col
          ></v-row
        >
      </v-card>
    </div>
  </div>
</template>
<script>
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
import moment from "moment";
import megamillionsVue from "./megamillions.vue";
export default {
  data() {
    return {
      //   transaction: [],
      datefrom: "",
      menufrom: false,
      search: "",
      lottowin: [],
      lotto: [],
      lottowinprize: [],
      orderlotto: [],
      orderpowerball: [],
      orderLao: [],
      orderThai: [],
      number: [],
      headers: [
        {
          text: "ลำดับ",
          value: "count",
          align: "center",
          width: "120px",
        },

        // {
        //   text: "หมายเลขลอตเตอรี่",
        //   value: "lotteryNo",
        //   align: "center",
        // },
        {
          text: "ราคารวม",
          value: "totalPrice",
          align: "center",
          width: "150px",
        },
        {
          text: "จ่ายเงินเมื่อ",
          value: "updatedAt",
          align: "center",
          width: "200px",
        },
        {
          text: "สถานะ",
          value: "status",
          align: "center",
          width: "120px",
        },
        { text: "ตัวเลือก", value: "action", align: "center", width: "120px" },
      ],
      tabs: "lotterythai",
      tabitems: [
        { text: "สลากกินแบ่งรัฐบาลไทย", key: "lotterythai" },
        { text: "สลากที่ถูกรางวัล", key: "winprize" },
        // { text: "PowerBall USA", key: "powerball" },
        // { text: "Mega Millions", key: "megamillions" },
        // { text: "Euro Jackpot", key: "eurojackpot" },
        // { text: "PowerBall Australia", key: "powerBallAus" },
        // { text: "Mini Loto Japan", key: "minilotto" },
        // { text: "ลาวพัดทะนา", key: "lao" },
        // Powerball USA
        // Mega Millions
        // Euro Jackpot
        // Powerball Australia
        // Mini Loto Japan
        // ลาวพัดทะนา
      ],
      // tabOne: false,
      // tabTwo: false,
      // couponCode: null
      // createdAt: "2021-06-28T04:35:23.000Z"
      // deliveryAddress: Object
      // deliveryStatus: "WAITING"
      // id: 196
      // isDelivery: true
      // lotteryDate: null
      // lotteryTransaction: Array(0)
      // status: "SUCCESS"
      // totalPrice: 80
      // updatedAt: "2021-06-28T04:35:26.000Z"
      // userId: 4
      list: [],
      count: 0,
      isOpenDiv: false,
    };
  },
  created() {
    if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
      this.isOpenDiv = true;
      // this.getTranx();
      this.getlotterythai();
    } else {
      this.isOpenDiv = false;
    }
    // this.tab('lotterythai');
  },
  methods: {
    gotomember() {
      this.$router.push("/member");
    },
    async getLottery() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAllLotteryByUser/` +
          user.user.id +
          "?lotteryDate=" +
          this.datefrom,
        auth
      );
      console.log("getlotto", response.data.data);
      this.lotto = response.data.data;
    },
    winprize() {
      window.open("https://www.bluedragonlottery.shop/winlotteryform");
    },
    member() {
      this.$router.push("member");
    },
    tranxDetail(val) {
      console.log(val);
      localStorage.setItem("transactionDetail", encode(val));
      this.$router.push("transactionDetail");
    },
    tranxDetailPowerball(val, tabs) {
      console.log(val, tabs);
      const data = {
        id: val,
        type: tabs,
      };
      localStorage.setItem("transactionPowerballDetail", encode(data));
      this.$router.push("transactionPowerballDetail");
    },
    tranxDetailLao(val) {
      console.log(val);
      localStorage.setItem("transactionLaoDetail", encode(val));
      this.$router.push("transactionLaoDetail");
    },
    async tab(val) {
      console.log(val.key);
      this.orderpowerball = [];
      this.orderLao = [];
      this.orderlotto = [];
      if (val.key == "lao") {
        this.tabs = "lao";
        this.getTrxLAO();
      }
      if (val.key == "powerball") {
        this.tabs = "powerball";
        this.getTranx();
      }
      if (val.key == "powerBallAus") {
        this.tabs = "powerBallAus";
        this.getpowerBallAus();
      }
      if (val.key == "eurojackpot") {
        this.tabs = "eurojackpot";
        this.geteurojackpot();
      }
      if (val.key == "megamillions") {
        this.tabs = "megamillions";
        this.getmegamillions();
      }
      if (val.key == "minilotto") {
        this.tabs = "minilotto";
        this.getminilotto();
      }
      if (val.key == "lotterythai") {
        this.tabs = "lotterythai";
        this.getlotterythai();
      }
      if (val.key == "winprize") {
        this.tabs = "winprize";
      }
      // if (val.key == "lottery") {
      //   this.tabs = "lottery";
      //   this.getLottery();
      // }

      // if (val.key == "winprize") {
      //   this.tabs = "winprize";
      //   var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      //   const auth = {
      //     headers: { Authorization: `Bearer ${user.token}` },
      //   };
      //   console.log("user", user);
      //   const response = await this.axios.get(
      //     `${process.env.VUE_APP_API}/transaction/findAllLotteryByUser/` +
      //       user.user.id,
      //     auth
      //   );
      //   console.log("getlotto", response.data.data);
      //   this.lotto = response.data.data;
      // }
    },
    tabOne() {
      this.tab = "tabOne";
    },
    tabTwo() {
      this.tab = "tabTwo";
    },
    convertDate(date) {
      // if (value.updatedAt) {
      return moment(String(date)).format("MM/DD/YYYY HH:mm");
      // }
    },
    convertDateBuy(date) {
      // if (value.updatedAt) {
      return moment(String(date)).format("MM/DD/YYYY HH:mm");
      // }
    },
    async getTranx() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAllByUser/` + user.user.id,
        auth
      );
      console.log("getall", response.data.data);
      let getlotto = response.data.data;
      this.orderlotto = response.data.data;

      const responsePowerball = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/getAllPowerballTrxByUserId/` +
          user.user.id,
        auth
      );

      console.log("orderlotto", this.orderlotto);
      console.log("getpowerball", responsePowerball.data.data);
      this.orderpowerball = responsePowerball.data.data;
      //   getlotto.forEach((data) => {
      //     if (data.status == "SUCCESS") {
      //       this.lotto.push(data);
      //     }
      //   });
      // this.orderlotto = getlotto.filter((data) => {
      //   return data.status === "SUCCESS";
      // });
      // this.lotto.forEach((element) => {
      //   this.list = element.lottery;
      // });
      console.log("this.lotto", this.orderlotto);
    },

    async getlotterythai() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/findAllByUser/` + user.user.id,
        auth
      );
      console.log("getall เข้าหวยไทย เย้เย้", response.data.data);
      let getlotto = response.data.data;
      this.orderThai = response.data.data;

      // const responsePowerball = await this.axios.get(
      //   `${process.env.VUE_APP_API}/transaction/getAllPowerballTrxByUserId/` +
      //     user.user.id,
      //   auth
      // );

      // console.log("orderlotto", this.orderlotto);
      // console.log("getpowerball", responsePowerball.data.data);
      // this.orderpowerball = responsePowerball.data.data;

      console.log("this.orderThai", this.orderThai);
    },
    async getpowerBallAus() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionBlue/findAllByUser/` +
          user.user.id,
        auth
      );
      console.log("getall", response.data.data);
      let getlotto = response.data.data;
      this.orderlotto = response.data.data;

      const responsePowerball = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionBlue/getAllPowerballTrxByUserId/` +
          user.user.id,
        auth
      );

      console.log("orderlotto", this.orderlotto);
      console.log("getpowerball", responsePowerball.data.data);
      this.orderpowerball = responsePowerball.data.data;

      console.log("this.lotto", this.orderlotto);
    },
    async getmegamillions() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionMegaMillions/findAllByUser/` +
          user.user.id,
        auth
      );
      console.log("getall", response.data.data);
      let getlotto = response.data.data;
      this.orderlotto = response.data.data;

      const responsePowerball = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionMegaMillions/getAllPowerballTrxByUserId/` +
          user.user.id,
        auth
      );

      console.log("orderlotto", this.orderlotto);
      console.log("getpowerball", responsePowerball.data.data);
      this.orderpowerball = responsePowerball.data.data;

      console.log("this.lotto", this.orderlotto);
    },
    async getminilotto() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionLotteryjapan/findAllByUser/` +
          user.user.id,
        auth
      );
      console.log("getall", response.data.data);
      let getlotto = response.data.data;
      this.orderlotto = response.data.data;

      const responsePowerball = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionLotteryjapan/getAllPowerballTrxByUserId/` +
          user.user.id,
        auth
      );

      console.log("orderlotto", this.orderlotto);
      console.log("getpowerball", responsePowerball.data.data);
      this.orderpowerball = responsePowerball.data.data;

      console.log("this.lotto", this.orderlotto);
    },
    async geteurojackpot() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionEurojackpot/findAllByUser/` +
          user.user.id,
        auth
      );
      console.log("getall", response.data.data);
      let getlotto = response.data.data;
      this.orderlotto = response.data.data;

      const responsePowerball = await this.axios.get(
        `${process.env.VUE_APP_API}/transactionEurojackpot/getAllPowerballTrxByUserId/` +
          user.user.id,
        auth
      );

      console.log("orderlotto", this.orderlotto);
      console.log("getpowerball", responsePowerball.data.data);
      this.orderpowerball = responsePowerball.data.data;

      console.log("this.lotto", this.orderlotto);
    },
    async getTrxLAO() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      console.log("user", user);

      const responsePowerball = await this.axios.get(
        `${process.env.VUE_APP_API}/transaction/getAllLAOTrxByUserId/` +
          user.user.id,
        auth
      );
      console.log("getLao", responsePowerball.data.data);
      this.orderLao = responsePowerball.data.data;
      //   getlotto.forEach((data) => {
      //     if (data.status == "SUCCESS") {
      //       this.lotto.push(data);
      //     }
      //   });
      // this.orderlotto = getlotto.filter((data) => {
      //   return data.status === "SUCCESS";
      // });
      // this.lotto.forEach((element) => {
      //   this.list = element.lottery;
      // });
      console.log("this.orderLao", this.orderLao);
    },
  },
};
</script>
<style scope>
.info2 {
  width: 15%;
  height: 5%;
  margin-top: -90px;
  margin-left: 240px;
  position: relative;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 5%;
  max-height: 10%;
  background: #f3e0bf73;
  backdrop-filter: blur(4px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
}
.winprizecard {
  background-image: linear-gradient(
    to left,
    #e8be50 0%,
    #f6e094 35%,
    #f9f295 50.39%,
    #e5c24c 100%
  );
  color: white;
  font-weight: 800;
}
</style>
